.BlogCard {
  width: 100%;
  height: 100%;
  padding: rem(48px) rem(16px);

  border-bottom: 1px solid #ccc;

  &__link {
    display: block;
    width: 100%;
    text-decoration: none;
    color: var(--color-black);

    &:hover {
      color: #555;
    }
  }

  &__image {
    width: 100%;
    margin-bottom: rem(16px);

    & img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__title {
    font-size: $h3;
    margin-bottom: rem(4px);
  }

  &__date {
    font-size: $subtitle-1;
    color: #555;
  }
}