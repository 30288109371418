.BlogPost {
  width: 100%;
  height: 100%;
  color: var(--color-black);

  &__inner {
    max-width: 40em;
    margin: rem(60px) auto;

    @include respond(phone) {
      margin: rem(32px) auto;
    }
  }

  &__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: rem(16px) rem(16px);
  }

  &__content {
    margin-top: rem(60px);
    padding: rem(0px) rem(16px);

    @include respond(phone) {
      margin-top: rem(32px);
    }
  }

  & p {
    margin-bottom: rem(16px);
  }
}
