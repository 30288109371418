.ContactUs {
  width: 100%;

  &__inner {
    height: calc(100vh - 70px);
    max-width: rem(1200px);
    margin: -70px auto 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
