// @param {string} $size - Value to be converted in PX
// @returns {string} - Returns REM string
@function rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// @param {string} $breakpoint - Viewport width
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: $bp-small-screen) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: $bp-tablet-screen) {
      @content;
    }
  }

  @if $breakpoint == desktop-md {
    @media only screen and (max-width: $bp-big-screen-md) {
      @content;
    }
  }
}
