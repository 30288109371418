@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Nunito:wght@400;700&display=swap');

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: rem(16px);
}

body {
  font-family: $default-font;
  font-size: rem(16px);
  font-weight: 400;
  line-height: 1.6;
  background-color: var(--color-white);
  color: var(--color-black);
}