.Home {
  width: 100%;

  &__header {
    width: 100%;
    height: calc(100vh - 70px);

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__header-inner {
    max-width: rem(1200px);
    margin: 0 auto;
    padding: rem(32px);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(50vh, mincontent) auto;
    grid-template-areas:
      "content media"
      "store store";

    @include respond(tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(30vh, mincontent) auto;
      grid-template-areas: "content";
    }

    align-items: center;
    column-gap: rem(24px);
  }

  &__header-content {
    grid-area: content;

    @include respond(phone) {
      h1 {
        font-size: rem(32px);
      }
    }

    & p {
      margin-bottom: rem(24px);
    }

    & button {
      font-size: rem(14px);
    }
  }

  &__header-media {
    grid-area: media;
    justify-self: center;

    @include respond(tablet) {
      display: none;
    }
  }

  &__header-img {
    max-width: rem(500px);
    height: rem(500px);

    & img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__header-platforms {
    @include respond(tablet) {
      padding-top: rem(16px);
    }
  }

  &__header-platform-icons {
    display: flex;

    & span {
      margin-right: rem(16px);
    }
  }

  .become-a-tester {
    background-color: #111;
    display: inline-block;
    padding: rem(16px) rem(16px);
    margin-bottom: rem(24px);
    text-decoration: none;
    color: #fff;
    border-radius: rem(8px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #000;

    @include respond(phone) {
      border-top-left-radius: rem(8px);
      border-bottom-left-radius: rem(8px);
      margin-top: rem(8px);
    }
  }

  .email-input {
    width: rem(250px);
    padding: rem(16px) rem(16px);
    border-radius: rem(8px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: 0;
    border: 1px solid #666;

    @include respond(phone) {
      border-top-right-radius: rem(8px);
      border-bottom-right-radius: rem(8px);
    }
  }

  .form-error {
    color: #ff495c;
    font-size: rem(14px);
  }

  .form-success {
    color: #2FA874;
    font-size: rem(14px);
  }
}

/* How It Works*/
.Hiw {
  width: 100%;
  height: 100%;

  &__inner {
    max-width: rem(900px);
    margin: auto;
    padding: rem(60px) 0;
  }

  &__title {
    font-size: rem(32px);
    text-align: center;
    margin-bottom: rem(32px);

    @include respond(phone) {
      font-size: rem(24px);
      margin-bottom: rem(32px);
    }
  }

  &__subtitle {
    margin-bottom: rem(24px);
  }

  &__steps {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;

    @include respond(phone) {
      flex: 0 0 100%;
      padding: 0 rem(16px);
    }
  }

  &__video {
    flex: 0 0 50%;

    & img {
      width: auto;
      height: rem(400px);
      object-fit: contain;

      @include respond(phone) {
        width: 100%;
        height: 100%;
      }
    }

    @include respond(phone) {
      flex: 0 0 100%;
    }
  }

  &__step {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    margin-bottom: rem(16px);
  }

  &__step-indicator {
    background-color: #000;
    color: #fff;
    width: rem(32px);
    height: rem(32px);
    border-radius: 50%;
    text-align: center;
    padding: 4px 12px;
    margin-right: rem(8px);
  }

  &__merchant-wrapper {
    display: flex;
    align-items: center;

    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__buyer-wrapper {
    display: flex;
    align-items: center;

    @include respond(phone) {
      flex-direction: column-reverse;
    }
  }

  &__buy-screnshots {
    @include respond(phone) {
      margin-top: rem(32px);
    }
  }

  &__step-instruction {
  }
}

/* Screenshots*/
.Screenshots {
  width: 100%;
  height: 100%;

  &__inner {
    max-width: rem(1200px);
    padding: rem(60px) 0;
    margin: auto;
  }

  &__title {
    font-size: rem(32px);
    text-align: center;
    margin-bottom: rem(32px);
  }

  &__images {
    display: flex;
    justify-content: space-between;

    @include respond(phone) {
      flex-direction: column;
      padding: 0 rem(16px);
    }
  }

  &__image {
    flex: 0 0 30%;

    & img {
      width: 100%;
      height: auto;
    }
  }
}
