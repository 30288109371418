%heading {
  font-family: $default-font;
  font-weight: 400;
}

.heading-1 {
  @extend %heading;
  font-size: $h1;
  @include respond(tablet) {
    font-size: $h2;
  }
  line-height: 1.4;
  font-weight: 900;
}

.heading-2 {
  @extend %heading;
  font-size: $h2;
  letter-spacing: 0.5px;
}

.heading-3 {
  @extend %heading;
  font-size: $h3;
  letter-spacing: 0.25px;
}

.caption {
  font-size: $caption;
  letter-spacing: 0.4px;
}

