.BlogImage {
  width: 100%;
  height: 100%;
  margin-top: rem(24px);
  margin-bottom: rem(24px);

  &__image {
    width: 100%;
    height: auto;
  }
}
