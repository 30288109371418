.border-bottom {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid var(--color-black);
  padding-bottom: rem(8px);
}

.btn {
  outline: none;
  // border: 0;
  background-color: transparent;
  font-size: rem(18px);
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    color: #555;
    transform: translateY(-3px);
  }
}