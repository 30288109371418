.BlogHeader {
  width: 100%;
  height: 100%;

  &__title {
    font-size: $h1;
    line-height: 1.4;
    color: var(--color-black);

    @include respond(phone) {
      font-size: $h3;
    }
  }

  &__date {
    font-weight: 400;
    color: #888;
  }
}
