.Blog {
  width: 100%;
  height: 100%;

  &__inner {
    max-width: 50em;
    margin: auto;
  }

  &__header {
    text-align: center;
    padding: rem(48px) rem(16px);
  }

  &__title {
    font-size: $h1;
    margin-bottom: rem(12px);
  }

  &__content {
    &--empty {
      margin-top: 10%;
      text-align: center;

      & h3 {
        font-size: $h3;
        margin-bottom: rem(32px);
      }

      & a {
        text-decoration: none;
        color: var(--color-black);
        padding: rem(12px) rem(16px);
        border: 1px solid var(--color-black);
        border-radius: rem(8px);

        &:hover {
          background: var(--color-black);
          color: var(--color-white);
        }
      }
    }
  }
}
