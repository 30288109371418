.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    justify-items: center;
  }
  
.modal-main {
    position:fixed;
    background: white;
    width: 50%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    justify-items: center;
  }
  
.display-block {
    display: block;
  }
  
.display-none {
    display: none;
  }

.modal-button {
    font-size: 12pt;
    font-family: tahoma;
    position:absolute;
    top:100%;
    right:50%;
    align-content: center;
}