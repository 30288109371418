  :root {
  // COLORS
  --color-black: #111;
  --color-white: #fff;
}

// FONTS SIZES 48, 34, 24, 20, 16, 12, 14
$headline: rem(96px);
$h1: rem(48px);
$h2: rem(34px);
$h3: rem(24px);
$subtitle-1: rem(16px);
$subtitle-2: rem(16px);
$body-text: rem(16px);
$button-text: rem(14px);
$caption: rem(12px);

// FONT TYPES
$default-font: 'Nunito', sans-serif;

// MARGINS
.mb-sm {
  margin-bottom: rem(8px);
}
.mb-md {
  margin-bottom: rem(16px);
}
.mb-lg {
  margin-bottom: rem(24px);
}
.mb-xl {
  margin-bottom: rem(48px);
}

// BREAK POINTS
$bp-big-screen-xl: rem(1200px);
$bp-big-screen-md: rem(1100px);
$bp-big-screen-sm: rem(900px);
$bp-tablet-screen: rem(767px);
$bp-small-screen: rem(500px);
