.Navbar {
  width: 100%;
  height: rem(70px);
  background-color: var(--color-white);

  &__container {
    max-width: rem(1200px);
    height: 100%;
    margin: 0 auto;
    padding: 0 rem(16px);

    display: flex;
    align-items: center;
  }

  &__logo {
    & a:link,
    a:visited {
      display: inline-block;
      text-decoration: none;
      color: var(--color-black);
      font-size: rem(24px);
      font-weight: 900;
      letter-spacing: 0.5px;
      transition: all 0.5s;
      font-family: 'Comfortaa', cursive;
    }

    & a:hover {
      transform: translateY(-3px);
    }

    & a:focus {
      outline: none;
    }
  }

  &__menu-links {
    display: flex;

    @include respond(phone) {
      display: block;
    }

    list-style-type: none;
    margin-left: auto;
  }

  &__menu-link {
    display: inline-block;
    margin-left: rem(48px);

    & a:link,
    a:visited {
      display: inline-block;
      text-decoration: none;
      font-size: rem(16px);
      color: #333;
      font-weight: 700;
      transition: all 0.5s;
    }

    & a:hover {
      color: #777;
      transform: translateY(-3px);
    }

    & a:focus {
      outline: none;
    }
  }

  &__btn {
    display: none;

    @include respond(phone) {
      display: none;
    }

    margin-left: auto;
    border: none;
    outline: none;
    color: var(--color-black);
    background-color: var(--color-white);
    font-weight: 700;
    font-size: rem(14px);
  }
}

// MOBILE DROP DOWN MENU
.MobileNavbar {
  width: 100%;
  height: calc(100vh - 70px);
  position: fixed;
  left: 0;
  background: var(--color-black-bg);
  animation-name: moveright;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  background: #eee;
  z-index: 1000;

  &__inner {
    padding: rem(32px) 0;
  }

  &__menu {
    font-size: rem(16px);

    display: flex;
    flex-direction: column;
    align-items: center;

    & a,
    a:link,
    a:visited {
      display: inline-block;
      text-decoration: none;
      color: var(--color-black);
      text-align: center;
      font-weight: 700;

      &:not(:last-of-type) {
        margin-bottom: rem(24px);
      }
    }
  }
}

@keyframes moveright {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  70% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
